import React from 'react'
import useAuth from '../firebase/useAuth'
import firebase, { FireBaseContext } from '../firebase'

const FbContext = ({ children }) => {
  const user = useAuth()

  return user ? (
    <FireBaseContext.Provider value={{ user, firebase }}>
      {children}
    </FireBaseContext.Provider>
  ) : (
    <FireBaseContext.Provider value={{ firebase }}>
      {children}
    </FireBaseContext.Provider>
  )
}

export { FbContext }
