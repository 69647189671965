// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blocks-js": () => import("../src/templates/blocks.js" /* webpackChunkName: "component---src-templates-blocks-js" */),
  "component---src-templates-list-js": () => import("../src/templates/list.js" /* webpackChunkName: "component---src-templates-list-js" */),
  "component---src-templates-landing-page-js": () => import("../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-single-js": () => import("../src/templates/single.js" /* webpackChunkName: "component---src-templates-single-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-js": () => import("../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-cases-js": () => import("../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-editprofile-js": () => import("../src/pages/editprofile.js" /* webpackChunkName: "component---src-pages-editprofile-js" */),
  "component---src-pages-faq-js": () => import("../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgot-password-js": () => import("../src/pages/forgotPassword.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-hire-js": () => import("../src/pages/hire.js" /* webpackChunkName: "component---src-pages-hire-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-new-user-js": () => import("../src/pages/newUser.js" /* webpackChunkName: "component---src-pages-new-user-js" */),
  "component---src-pages-pay-js": () => import("../src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-popup-js": () => import("../src/pages/popup.js" /* webpackChunkName: "component---src-pages-popup-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-signup-js": () => import("../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-successfreelancer-js": () => import("../src/pages/successfreelancer.js" /* webpackChunkName: "component---src-pages-successfreelancer-js" */),
  "component---src-pages-successhire-js": () => import("../src/pages/successhire.js" /* webpackChunkName: "component---src-pages-successhire-js" */),
  "component---src-pages-successprofile-js": () => import("../src/pages/successprofile.js" /* webpackChunkName: "component---src-pages-successprofile-js" */),
  "component---src-pages-thanks-js": () => import("../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

