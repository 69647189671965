import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'

import firebaseConfig from './config'

class Firebase {
  constructor() {
    if (typeof window !== 'undefined') {
      app.initializeApp(firebaseConfig)
      this.auth = app.auth()
      this.db = app.firestore()
      this.Db = app.database()
      this.storage = app.storage()
      this.googleProvider = new app.auth.GoogleAuthProvider()
      this.facebookProvider = new app.auth.FacebookAuthProvider()
      this.twitterProvider = new app.auth.TwitterAuthProvider()
    }
  }

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider)

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider)

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider)

  async register(name, email, password) {
    await this.auth.createUserWithEmailAndPassword(email, password)
  }
  async login(email, password) {
    return await this.auth.signInWithEmailAndPassword(email, password)
  }

  async customLogin(token) {
    return await this.auth.signInWithCustomToken(token)
  }
  async logout() {
    await this.auth.signOut()
  }
  async resetPassword(email) {
    await this.auth.sendPasswordResetEmail(email)
  }

  async fetchProviders(email) {
    await this.auth.fetchSignInMethodsForEmail(email)
  }

  // *** User API ***

  user = uid => this.Db.ref(`users/${uid}`)

  users = () => this.Db.ref('users')

  // *** Message API ***

  message = uid => this.db.ref(`messages/${uid}`)

  messages = () => this.db.ref('messages')
}

const firebase = new Firebase()
export default firebase
