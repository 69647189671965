import React from 'react'
import fireBase from './firebase'

function useAuth() {
  let initialState = { signIn: false }

  if (typeof window !== 'undefined') {
    const Firebase = fireBase
    initialState = Boolean(Firebase.auth.currentUser)
  }
  const [authUser, setAuthUser] = React.useState(initialState)
  React.useEffect(() => {
    const unsubscribe = fireBase.auth.onAuthStateChanged(user => {
      if (user) {
        setAuthUser(user)
      } else {
        setAuthUser(null)
      }
    })
    return () => unsubscribe()
  }, [])

  return authUser
}

export default useAuth
